/**
 * The Gatsby Browser API lets you respond to Gatsby-specific events within the browser, and wrap your page components in additional global components.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import React from 'react'
import { getSessionPassword } from './src/components/passwordProtect/utils'
import PasswordProtect from './src/components/passwordProtect/PasswordProtect'

export const wrapPageElement = () => {
	const password = process.env.PASSWORD_PROTECT

	// password protection disabled if PROD
	if (process.env.PRODUCTION === true || process.env.PRODUCTION === 'true') {
		return
	}

	// check password
	const passwordCandidate = getSessionPassword()
	if (passwordCandidate === password) {
		return
	}

	// ask for password
	return <PasswordProtect />
}


// Function to dynamically load the Google Analytics script
const loadGtag = (value) => {

    const existingScript = document.querySelector(`script[src*='googletagmanager.com/gtag/js']`);

    // Remove existing script if already present
    if (existingScript) {
        existingScript.remove();
        
        // Clear previous gtag instance
        delete window.gtag;
        window.dataLayer = [];
    }

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_KEY}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        window.gtag = gtag;
        gtag('js', new Date());

        // Configure Google Analytics
        gtag('config', process.env.GOOGLE_ANALYTICS_KEY, { 'anonymize_ip': !value });
    };

    script.onerror = () => {
        console.error("Failed to load Google Analytics script.");
    };
};

export const onClientEntry = () => {
    // Get user consent status from localStorage
	let consentGiven = false;
    const consentString = localStorage.getItem('consent');
    
    if (consentString) {
        try {
            consentGiven = JSON.parse(consentString);
        } catch (e) {
            console.error("Failed to parse consent from localStorage:", e);
        }
    }

    // Check if the user has given consent then load gtag script
    loadGtag(consentGiven);
    return;
};

// Function to be called when the user gives consent
export const onConsentGiven = (value) => {
    const currentConsent = localStorage.getItem('consent');

    // Only reload the script if the consent value has changed
    if (currentConsent !== JSON.stringify(value)) {
        localStorage.setItem('consent', JSON.stringify(value));

        // Load script
        loadGtag(value);
    }
};
